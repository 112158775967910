import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { UserTreesScreen_Query } from "@generated/UserTreesScreen_Query.graphql";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { MainContainer } from "../containers/MainContainer";
import { FachberaterStatus } from "../components/FachberaterStatus";

const DASHBOARD_QUERY = graphql`
    query UserTreesScreen_Query {
        Viewer {
            Auth {
                currentUser {
                    user {
                        extension(userExtensionKind: InfectopharmUserExtension) {
                            ... on InfectopharmUserExtension {
                                salutation
                                title
                                lastName
                            }
                        }
                        ...DefaultContainer_UserDataFragment
                        ...UserStatusMessage_UserFragment
                        ...PointsPoolDisplay_UserFragment
                        ...FachberaterStatus_UserFragment
                    }
                }
            }
        }
    }
`;

export function UserTreesScreen() {
	usePageTitle("Meine Aktivitäten");

	const query = useLazyLoadQuery<UserTreesScreen_Query>(
		DASHBOARD_QUERY,
		{},
		{fetchPolicy: "network-only"},
	);

	return (
		<DefaultContainer
			collapsed={false}
			screenName="learn-states"
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user!}
		>
			<MainContainer>
				<FachberaterStatus userFragmentRef={query.Viewer.Auth.currentUser?.user!}/>
			</MainContainer>
		</DefaultContainer>
	);
}
