import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment$key } from "../../../__generated__/PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment.graphql";
import { Label } from "../../../infecto-lms-webapp/components/buttons/Label";
import { SecondaryButton } from "../../../infecto-lms-webapp/components/buttons/SecondaryButton";

const ATTACHMENTS_FRAGMENT = graphql`
	fragment PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		root {
			id
			attachmentsV2 {
				title
				description
				... on InfectopharmFileAttachment {
					file {
						id
						url
						name
					}
				}
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment$key;
}

export const PaediaAttachmentButtonsDisplay = ({ learnOpportunityFragmentRef }: OwnProps) => {
	const learnOpportunity =
		useFragment<PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment$key>(
			ATTACHMENTS_FRAGMENT,
			learnOpportunityFragmentRef,
		);

	return (
		<AttachmentsContainer className="lg:pr-4 mb-6">
			{learnOpportunity.root?.attachmentsV2.map((attachment) => {
				return (
					<a
						href={attachment.file?.url!}
						rel="noopener noreferrer"
						className="p-button-text text-primary p-0 text-sm md:text-base w-full"
						download={attachment.file?.name!}
						target="_blank"
						key={attachment?.file?.name}
					>
						<AttachmentButton className="mt-4 mb-2">
							<SecondaryButton className="p-2 flex flex-column w-full text-sm">
								<Label>
									{attachment.title ? (
										<span dangerouslySetInnerHTML={{ __html: attachment.title }} />
									) : (
										<span dangerouslySetInnerHTML={{ __html: attachment.description! }} />
									)}
								</Label>
							</SecondaryButton>
						</AttachmentButton>
					</a>
				);
			})}
		</AttachmentsContainer>
	);
};

const AttachmentButton = styled.div`
	text-align: center;
	cursor: pointer;
	max-width: 400px;
`;
const AttachmentsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
