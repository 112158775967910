/**
 * @generated SignedSource<<02b15b22bb37dd038b092223eeae355a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FachberaterStatusTypeEnum = "eligibleForBasisseminar" | "isFachberater" | "notEligibleForBasisseminar" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LearningOutcomeDescriptionComponent_UserFragment$data = {
  readonly extension: {
    readonly fachberaterStatus?: {
      readonly statusType: FachberaterStatusTypeEnum;
    };
  } | null;
  readonly " $fragmentType": "LearningOutcomeDescriptionComponent_UserFragment";
};
export type LearningOutcomeDescriptionComponent_UserFragment$key = {
  readonly " $data"?: LearningOutcomeDescriptionComponent_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LearningOutcomeDescriptionComponent_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearningOutcomeDescriptionComponent_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "userExtensionKind",
          "value": "InfectopharmUserExtension"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "fachberaterStatus",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "statusType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "InfectopharmUserExtension",
          "abstractKey": null
        }
      ],
      "storageKey": "extension(userExtensionKind:\"InfectopharmUserExtension\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "648645d991b26301ece4cef775f750f7";

export default node;
