/**
 * @generated SignedSource<<95c1115e2e4f30817fa759ae4f6d5595>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment$data = {
  readonly root: {
    readonly attachmentsV2: ReadonlyArray<{
      readonly description: string | null;
      readonly file?: {
        readonly id: string;
        readonly name: string;
        readonly url: string | null;
      } | null;
      readonly title: string | null;
    }>;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment";
};
export type PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "attachmentsV2",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "file",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "InfectopharmFileAttachment",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "44730a1f5e5818bf619ff47f0ae21e30";

export default node;
