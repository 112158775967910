import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import {
	LearningOutcomeDescriptionComponent_NodeFragment$key
} from "@generated/LearningOutcomeDescriptionComponent_NodeFragment.graphql";
import { useFragment } from "react-relay";
import {
	LearningOutcomeDescriptionComponent_UserFragment$key
} from "@generated/LearningOutcomeDescriptionComponent_UserFragment.graphql";
import { singularPluralPointsDisplay } from "../../../infecto-lms-webapp/functions/helpers/singularPluralPointsDisplay";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
    fragment LearningOutcomeDescriptionComponent_NodeFragment on LearnOpportunityV2 {
        root {
            id
            structureDefinition {
                title
                ... on LearnOpportunityRootStructureDefinition {
                    extension {
                        ... on LearnOpportunityRootExtensionImpl {
                            reducedDataInTree {
                                pacPoints
                                pharmaPoints
                            }
                            onPercentagePassContentChildConfig {
                                requiredPercentage
                            }
                            numOfQuestionsInChildren
                        }
                    }
                }
            }
        }
    }
`;

const FRAGMENT = graphql`
    fragment LearningOutcomeDescriptionComponent_UserFragment on User {
        extension(userExtensionKind: InfectopharmUserExtension) {
            ... on InfectopharmUserExtension {
                fachberaterStatus {
                    statusType
                }
            }
        }
    }
`;

interface OwnProps {
	learnOpportunityFragmentRef: LearningOutcomeDescriptionComponent_NodeFragment$key;
	userFragmentRef: LearningOutcomeDescriptionComponent_UserFragment$key;
}

export const LearningOutcomeDescriptionComponent = ({
	                                                    learnOpportunityFragmentRef,
	                                                    userFragmentRef,
                                                    }: OwnProps) => {
	const user = useFragment<LearningOutcomeDescriptionComponent_UserFragment$key>(
		FRAGMENT,
		userFragmentRef,
	);
	const learnOpportunity = useFragment<LearningOutcomeDescriptionComponent_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);

	const isFachberater = user.extension?.fachberaterStatus?.statusType == "isFachberater";

	const numberOfQuestions =
		learnOpportunity.root?.structureDefinition.extension?.numOfQuestionsInChildren;

	const percentageOfCorrectAnswers =
		learnOpportunity.root?.structureDefinition.extension?.onPercentagePassContentChildConfig
			?.requiredPercentage;

	const neededAmountOfCorrectQuestions =
		numberOfQuestions !== undefined && percentageOfCorrectAnswers !== undefined
			? numberOfQuestions * (percentageOfCorrectAnswers / 100)
			: 0;

	return (
		<div className="text-base">
			<p>
				<u>Lernerfolgskontrolle</u>
			</p>
			<p>
				{`Mit dem erfolgreichen Abschluss (mind. ${Math.ceil(
					neededAmountOfCorrectQuestions,
				)} von ${numberOfQuestions} Fragen richtig) können Sie ${
					learnOpportunity.root?.structureDefinition.extension?.reducedDataInTree?.pharmaPoints
				} BAK-${singularPluralPointsDisplay(
					learnOpportunity.root?.structureDefinition.extension?.reducedDataInTree?.pharmaPoints!,
				)}
    erwerben${
					isFachberater
						? ` und für die Rezertifizierung als Fachberater*in Pädiatrie ${learnOpportunity.root?.structureDefinition.extension?.reducedDataInTree?.pacPoints} 
          PädiaAkademie-Credits (PAC) sammeln.`
						: "."
				}
    Bitte beachten Sie, dass pro Frage mindestens eine Antwort richtig ist.
  `}
			</p>
			<p>Wir wünschen Ihnen viel Erfolg!</p>
		</div>
	);
};
