/**
 * @generated SignedSource<<86987299933ab659d40c8f4bb34dd7c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FachberaterStatusTypeEnum = "eligibleForBasisseminar" | "isFachberater" | "notEligibleForBasisseminar" | "%future added value";
export type Salutation = "Divers" | "Mr" | "Mrs" | "Unknown" | "%future added value";
export type DashboardScreen_Query$variables = {};
export type DashboardScreen_Query$data = {
  readonly Learn: {
    readonly NodesSortedToTags: ReadonlyArray<{
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"CarousalNode_NodeFragment">;
      }>;
      readonly tag: {
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly extension: {
            readonly efnNumber?: string | null;
            readonly fachberaterStatus?: {
              readonly statusType: FachberaterStatusTypeEnum;
            };
            readonly firstName?: string | null;
            readonly lastName?: string | null;
            readonly salutation?: Salutation | null;
            readonly title?: string | null;
          } | null;
          readonly " $fragmentSpreads": FragmentRefs<"PaediaDefaultContainer_UserDataFragment" | "UserStatusMessage_UserFragment">;
        };
      } | null;
    };
  };
};
export type DashboardScreen_Query = {
  response: DashboardScreen_Query$data;
  variables: DashboardScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "userExtensionKind",
    "value": "InfectopharmUserExtension"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salutation",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "efnNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "tag",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v0/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "fachberaterStatus",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "InfectopharmUserExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(userExtensionKind:\"InfectopharmUserExtension\")"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PaediaDefaultContainer_UserDataFragment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserStatusMessage_UserFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnQueries",
        "kind": "LinkedField",
        "name": "Learn",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NodesSortedToTag",
            "kind": "LinkedField",
            "name": "NodesSortedToTags",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunityV2",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CarousalNode_NodeFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v0/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "fachberaterStatus",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "globalUserStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "country",
                                "storageKey": null
                              }
                            ],
                            "type": "InfectopharmUserExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(userExtensionKind:\"InfectopharmUserExtension\")"
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnQueries",
        "kind": "LinkedField",
        "name": "Learn",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NodesSortedToTag",
            "kind": "LinkedField",
            "name": "NodesSortedToTags",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunityV2",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "structureDefinition",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "icon",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "type": "LearnOpportunityRootStructureDefinition",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "89f742e45af3a7e00ab8cb15d80504b4",
    "id": null,
    "metadata": {},
    "name": "DashboardScreen_Query",
    "operationKind": "query",
    "text": "query DashboardScreen_Query {\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          extension(userExtensionKind: InfectopharmUserExtension) {\n            __typename\n            ... on InfectopharmUserExtension {\n              salutation\n              title\n              firstName\n              lastName\n              efnNumber\n              fachberaterStatus {\n                __typename\n                statusType\n              }\n            }\n          }\n          ...PaediaDefaultContainer_UserDataFragment\n          ...UserStatusMessage_UserFragment\n          id\n        }\n      }\n    }\n  }\n  Learn {\n    NodesSortedToTags {\n      tag {\n        id\n        name\n      }\n      nodes {\n        id\n        ...CarousalNode_NodeFragment\n      }\n    }\n  }\n}\n\nfragment CarousalNode_NodeFragment on LearnOpportunityV2 {\n  id\n  image {\n    url\n    id\n  }\n  structureDefinition {\n    __typename\n    title\n    ... on LearnOpportunityRootStructureDefinition {\n      icon {\n        url\n        id\n      }\n    }\n  }\n}\n\nfragment MainTopBar_UserDataFragment on User {\n  ...ProfileLink_UserDataFragment\n}\n\nfragment PaediaDefaultContainer_UserDataFragment on User {\n  ...MainTopBar_UserDataFragment\n}\n\nfragment ProfileLink_UserDataFragment on User {\n  extension(userExtensionKind: InfectopharmUserExtension) {\n    __typename\n    ... on InfectopharmUserExtension {\n      salutation\n      title\n      lastName\n      firstName\n      efnNumber\n    }\n  }\n}\n\nfragment UserStatusMessage_UserFragment on User {\n  extension(userExtensionKind: InfectopharmUserExtension) {\n    __typename\n    ... on InfectopharmUserExtension {\n      globalUserStatus\n      country\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6e2e793c50142849fab18cfe4151b14";

export default node;
