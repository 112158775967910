/**
 * @generated SignedSource<<e984b338c4f8e656350c6019123fb550>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FachberaterStatusTypeEnum = "eligibleForBasisseminar" | "isFachberater" | "notEligibleForBasisseminar" | "%future added value";
export type ImportedPacPointType = "AbsolvierteLernerfolgskontrollen" | "BesuchtePraesenzfortbildungen" | "%future added value";
export type PacPointChangeKindEnum = "awarded" | "legacy" | "manuallyImported" | "statusExtension" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FachberaterStatus_UserFragment$data = {
  readonly extension: {
    readonly fachberaterStatus?: {
      readonly changes?: ReadonlyArray<{
        readonly amount: number;
        readonly eventDate?: any;
        readonly eventNumber?: string;
        readonly eventTitle?: string;
        readonly eventType?: ImportedPacPointType;
        readonly kind: PacPointChangeKindEnum;
        readonly receivedAt: string;
      }>;
      readonly needsToBeCheckedAfter?: string;
      readonly statusType: FachberaterStatusTypeEnum;
    };
    readonly fachberaterStatusHistory?: ReadonlyArray<{
      readonly changes?: ReadonlyArray<{
        readonly amount: number;
        readonly eventDate?: any;
        readonly eventNumber?: string;
        readonly eventTitle?: string;
        readonly eventType?: ImportedPacPointType;
        readonly kind: PacPointChangeKindEnum;
        readonly receivedAt: string;
      }>;
      readonly needsToBeCheckedAfter?: string;
    }>;
  } | null;
  readonly " $fragmentType": "FachberaterStatus_UserFragment";
};
export type FachberaterStatus_UserFragment$key = {
  readonly " $data"?: FachberaterStatus_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FachberaterStatus_UserFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "needsToBeCheckedAfter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "changes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "receivedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eventTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eventDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eventType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eventNumber",
              "storageKey": null
            }
          ],
          "type": "ImportedPacPointAddition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IsFachberater",
  "abstractKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FachberaterStatus_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "userExtensionKind",
          "value": "InfectopharmUserExtension"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "fachberaterStatus",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "statusType",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "fachberaterStatusHistory",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "InfectopharmUserExtension",
          "abstractKey": null
        }
      ],
      "storageKey": "extension(userExtensionKind:\"InfectopharmUserExtension\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "2be957830728d198c770f3a8900abeac";

export default node;
