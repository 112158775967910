import React from "react";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import { graphql } from "babel-plugin-relay/macro";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { useLazyLoadQuery } from "react-relay";
import { DashboardScreen_Query } from "@generated/DashboardScreen_Query.graphql";
import { PaediaDefaultContainer } from "../containers/PaediaDefault.container";
import { ErrorPageComponent } from "../../infecto-lms-webapp/components/ErrorPage.component";

const PAGENOTFOUND_QUERY = graphql`
    query PageNotFoundScreen_Query {
        Viewer {
            Auth {
                currentUser {
                    user {
                        extension(userExtensionKind: InfectopharmUserExtension) {
                            ... on InfectopharmUserExtension {
                                salutation
                                title
                                lastName
                                firstName
                                efnNumber
                                country
                            }
                        }
                        ...PaediaDefaultContainer_UserDataFragment
                    }
                }
            }
        }
    }
`;

export const PageNotFoundScreen = () => {
	usePageTitle("PageNotFound");
	const query = useLazyLoadQuery<DashboardScreen_Query>(PAGENOTFOUND_QUERY, {});

	return (
		<PaediaDefaultContainer
			collapsed={false}
			screenName="dashboard"
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user}
		>
			<MainContainerWithTopBar>
				<ErrorPageComponent
					errorType={"404"}
					title={"Die gewünschte Seite wurde nicht gefunden."}
					description={
						"Entschuldigung, die Seite, die Sie suchen, konnte nicht gefunden werden oder wurde entfernt."
					}
				/>
			</MainContainerWithTopBar>
		</PaediaDefaultContainer>
	);
};
