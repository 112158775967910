/**
 * @generated SignedSource<<4458967607aab54ee9807c11547d7ddf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StructureType = "child" | "root" | "%future added value";
export type TypeDefinitionType = "branch" | "content" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NodeLeftFrame_NodeFragment$data = {
  readonly id: string;
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly root: {
    readonly id: string;
    readonly structureDefinition: {
      readonly extension?: {
        readonly configConsequences?: {
          readonly isRewardedTo: string | null;
          readonly restartTriesCount: number | null;
          readonly rewardExpired: boolean;
          readonly showAnswerExpired: boolean;
        };
        readonly lengthInSecondsOverride?: number | null;
        readonly reducedDataInTree?: {
          readonly pacPoints: number | null;
          readonly pharmaPoints: number | null;
        };
        readonly totalUserTries?: number | null;
      };
      readonly icon?: {
        readonly url: string | null;
      } | null;
      readonly tags?: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"NodeTagList_LearnOpportunityRootStructureDefinitionFragment">;
    };
  } | null;
  readonly shortDescription: string | null;
  readonly structureDefinition: {
    readonly definitionType: StructureType;
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly definitionType: TypeDefinitionType;
    readonly extension?: {
      readonly lengthInSecondsOverride?: number | null;
      readonly pacPoints?: number | null;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"AttachmentDisplay_LearnOpportunityV2Fragment" | "AttachmentHeftDisplay_LearnOpportunityV2Fragment" | "NodeBreadcrumbs_NodeFragment" | "NodeConfigurationsDisplay_NodeFragment" | "NodeContentElementsList_NodeFragment" | "NodeDescription_NodeFragment" | "NodeNavigationButtons_NodeFragment" | "PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment" | "ProjectContentButtonCME_LearnOpportunityFragment" | "ShowAnswersButton_LearnOpportunityFragment">;
  readonly " $fragmentType": "NodeLeftFrame_NodeFragment";
};
export type NodeLeftFrame_NodeFragment$key = {
  readonly " $data"?: NodeLeftFrame_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeLeftFrame_NodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lengthInSecondsOverride",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pacPoints",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definitionType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeLeftFrame_NodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tag",
                  "kind": "LinkedField",
                  "name": "tags",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "icon",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "InfectopharmTreeConfigConsequences",
                          "kind": "LinkedField",
                          "name": "configConsequences",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "restartTriesCount",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isRewardedTo",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rewardExpired",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "showAnswerExpired",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalUserTries",
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReducedDataInTree",
                          "kind": "LinkedField",
                          "name": "reducedDataInTree",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "pharmaPoints",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityRootExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NodeTagList_LearnOpportunityRootStructureDefinitionFragment"
                }
              ],
              "type": "LearnOpportunityRootStructureDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v4/*: any*/),
                    (v3/*: any*/)
                  ],
                  "type": "LearnContentExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectContentButtonCME_LearnOpportunityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AttachmentHeftDisplay_LearnOpportunityV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeNavigationButtons_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeBreadcrumbs_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeConfigurationsDisplay_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeContentElementsList_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AttachmentDisplay_LearnOpportunityV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeDescription_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShowAnswersButton_LearnOpportunityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PaediaAttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "9fa03446f506855e995507c41a1ac767";

export default node;
